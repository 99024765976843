<template>
  <div class="c-article">
    <FHBreadcrumbs v-if="crumbs"
      class="c-article__breadcrumbs u-py u-px"
      :crumbs="crumbs" />
    <VHeading header="h1"
      :text="article.name"
      class="c-article__title u-px u-px--3" />
    <ArticleElement :article="article" />
    <Modal />
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { fetchWithAuth } from '@/api'
import { fetchUnitBySlug } from '@/services/strapi'
import FHBreadcrumbs from '@forlagshuset/v-breadcrumbs'
import VHeading from '@forlagshuset/v-heading'
import ArticleElement from '@/components/ArticleWrapper'
import Modal from '@/components/Modal'

export default {
  components: {
    VHeading,
    FHBreadcrumbs,
    ArticleElement,
    Modal
  },

  setup (props, ctx) {
    const articleSlug = computed(() => ctx.root.$options.router.history.current.params.articleSlug)
    const unitSlug = computed(() => ctx.root.$options.router.history.current.params.unitSlug)
    const article = ref({})
    const crumbs = ref([])

    onMounted(async () => {
      article.value = await (await fetchWithAuth('article-types/' + articleSlug.value)).json()
      const unitName = await fetchUnitBySlug(unitSlug.value)

      //link breadcrumbs on tools back to tools
      let type = 'unit'
      if(unitSlug.value === 'aktor-verktoy') {
        type= 'tools'
      }

      crumbs.value.push({
        type: type,
        name: unitName.name,
        slug: unitSlug.value
      })

      crumbs.value.push({
        type: 'article',
        name: article.value.name,
        slug: articleSlug.value
      })
    })

    return {
      article,
      crumbs
    }
  }
}
</script>

<style lang="scss">
.c-article {
  .c-article-element {
    .c-article__title {
      display: none;
    }
  }
  .c-banner {
    .c-banner__fig {
      background: none !important;

      .c-banner__img {
        margin: 0 auto;
        width: auto;
        height: 450px;
      }
    }
  }
}
.c-article__breadcrumbs {
  font-family: CircularPro, sans-serif;
  position: relative;
  z-index: 1;
  .c-breadcrumbs__home {
    display: none;
  }
  .c-breadcrumbs__link {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>